#Catalog {
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: #ed1c24;
  //background-image: url(../Images/CatalogRight.png), url(../Images/CatalogLeft.png);
  background-position: bottom left, center right;
  background-repeat: no-repeat;
    .yellow {
        color: white;
        margin-bottom: 50px;
    }
    .Info {
        color: #fff;
        padding-top: 20px;
        font-size: 14px;
    }
    h4 {
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .Separator {
        margin-top: 230px;
        margin-bottom: 20px;
        border-top: 2px solid #fff;
        width: 40px;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: none;
            opacity: .8;
        }
    }
    .Icon {
        font-size: 18px;
        color: #fff;
        margin-right: 10px;
        span {
            vertical-align: bottom;
        }
    }
    .booklet .b-page {
        outline:1px solid transparent;
    }
    .booklet .b-wrap-left{
        background: none;
    }
    .booklet .b-wrap{
        padding: 0!important;
    }
    @media (max-width: 1500px){
        .mybook-block{
            margin: 0 auto;
            float: none;
        }
        .Info{
            width: 100%;
            max-width: 766px;
            margin: 0 auto;
            float: none;
        }
        .Separator{
            margin-top: 100px;
        }
    }
}
